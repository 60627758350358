<template>
  <v-card>
    <v-alert
      :value="createdSuccess"
      type="success"
      class="my-0"
    >Оплата изменена</v-alert>
    <v-form
      ref="form"
      lazy-validation
    >
      <v-card-title
        class="px-4"
      >
        <span class="headline">Просмотр оплаты №{{ item.id }}</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text
        class="px-4"
      >
        <v-text-field
          label="Дата"
          :value="new Date(item.created_at).toLocaleString()"
          readonly
        ></v-text-field>
        <v-text-field
          label="Клиент"
          :value="(item.client) ? `${item.client.name} (${item.client.phone})` : ''"
          readonly
        ></v-text-field>
        <v-text-field
          label="Стоимость"
          :value="item.amount"
          readonly
        ></v-text-field>
        <v-text-field
          label="Тип оплаты"
          :value="(type = paymentTypes.find((elem) => elem.id === item.payment_type_id)) ? type.name : 'Неизвестный тип'"
          readonly
        ></v-text-field>
        <v-text-field
          label="Магазин"
          :value="(item.bouquet && storeList && storeList.find(elem => elem.id === item.bouquet.store_id)) ? storeList.find(elem => elem.id === item.bouquet.store_id).name : ''"
          readonly
        ></v-text-field>
        <!-- <v-text-field
          v-if="editedItem.parent && editedItem.paymentType.id === 7"
          label="Предыдущая оплата"
          :value="editedItem.parent.name"
          readonly
        ></v-text-field> -->
        <!-- <v-text-field
          v-if="editedItem.parent && editedItem.paymentType.id === 7"
          label="Дата предыдущей оплаты"
          :value="editedItem.parent.created_at"
          readonly
        ></v-text-field> -->
        <v-textarea
          label="Комментарий"
          auto-grow
          :value="item.comment"
          row-height="12"
          readonly
        ></v-textarea>
      </v-card-text>
      <v-flex xs12 class="px-4"> 
        <template v-if="historyShow">
          <v-textarea
            label="VendoTek"
            auto-grow
            :value="historyText"
            row-height="12"
            readonly
          ></v-textarea>
        </template>
        <template v-else>
          <template v-if="isVendoPaymentType">
            <v-btn @click.native="getVendoTek()">История</v-btn>
          </template>
        </template>
      </v-flex>
      <v-card-actions
        class="px-4 pb-4"
      >
        <v-btn
          @click.native="cancel()"
        >Отмена</v-btn>
        <!-- <v-spacer></v-spacer>
        <v-btn
          color="info"
          @click="submitForm"
        >Сохранить</v-btn> -->
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import axios from 'axios';

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    paymentTypes: {
      type: Array,
      default: () => [],
    },
    storeList: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      createdSuccess: false,
      historyShow: false,
      history: [],
      historyText: '',
    };
  },
  computed: {
    isVendoPaymentType() {
      // Check if the payment_type_id is either 1 or 2
      const type = this.paymentTypes.find((elem) => elem.id === this.item.payment_type_id);
      return type && (type.id === 16 || type.id === 17);
    },
  },
  methods: {
    getVendoTek() {
      let url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments-vendo-history`;
      let propsItem = {
        payment_id: this.item.id,
        bouquet_id: this.item.bouquet_id,
      };

      // Get bank order status if payment type is 17
      if (this.item.payment_type_id == 17) {
        url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments-bank-status`;
        propsItem = {
          bank_order_id: this.item.bank_order_id,
        };
      }

      axios
        .post(url, propsItem)
        .then((response) => {
          this.history = response.data;
          this.historyText = JSON.stringify(this.history, null, 2);
          this.historyShow = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cancel() {
      this.createdSuccess = false;
      this.historyShow = false;
      this.historyText = '';
      this.history = [];
      this.$emit('cancel');
    },
  },
};
</script>

<template>
  <v-container fluid class="pa-0">
    <v-dialog :value="loadingDialog" persistent max-width="320px">
      <v-list>
        <v-list-tile
          v-for="(item, index) in loadingData"
          :key="index"
          avatar
          :color="item.error ? 'red' : item.color"
        >
          <v-list-tile-avatar>
            <v-progress-circular
              :value="100"
              :size="30"
              :color="item.error ? 'red' : item.color"
              :indeterminate="item.loading"
            ></v-progress-circular>
          </v-list-tile-avatar>

          <v-list-tile-content>
            <v-list-tile-title>{{ item.title }}</v-list-tile-title>
          </v-list-tile-content>
        </v-list-tile>
      </v-list>
    </v-dialog>
    <template v-if="!loadingDialog">
      <v-card>
        <v-card-title>
          <v-flex>
            <v-layout row wrap>
              <v-flex xs2 class="px-2">
                <v-select
                  label="Тип"
                  :items="[...[{name: 'Все', id: null}], ...paymentTypes]"
                  item-text="name"
                  item-value="id"
                  v-model="filter.payment_type_id"
                  hide-details
                  clearable
                  no-data-text="Не надено"
                  @change="customFilter()"
                ></v-select>
              </v-flex>
              <v-flex xs2 class="px-2">
                <v-menu
                  :close-on-content-click="false"
                  v-model="dataStartPicker"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    label="Дата (с)"
                    v-model="filter.start_date"
                    prepend-icon="event"
                    hide-details
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="filter.start_date"
                    @input="dataStartPicker = false"
                    no-title
                    scrollable
                    locale="ru-ru"
                    first-day-of-week="1"
                    :max="!!filter.end_date ? filter.end_date : undefined"
                    @change="customFilter()"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs2 class="px-2">
                <v-menu
                  :close-on-content-click="false"
                  v-model="dataEndPicker"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <v-text-field
                    slot="activator"
                    label="Дата (по)"
                    v-model="filter.end_date"
                    prepend-icon="event"
                    hide-details
                    readonly
                  ></v-text-field>
                  <v-date-picker
                    v-model="filter.end_date"
                    @input="dataEndPicker = false"
                    no-title
                    locale="ru-ru"
                    scrollable
                    first-day-of-week="1"
                    :min="!!filter.start_date ? filter.start_date : undefined"
                    @change="customFilter()"
                  ></v-date-picker>
                </v-menu>
              </v-flex>
              <v-flex xs2 class="px-2">
                <v-select
                  label="Менеджер"
                  :items="usersList"
                  item-text="name"
                  item-value="id"
                  v-model="filter.manager_id"
                  hide-details
                  clearable
                  no-data-text="Не надено"
                  @change="customFilter()"
                ></v-select>
              </v-flex>
              <v-flex xs2 class="px-2">
                <v-autocomplete
                  label="Клиент"
                  :items="clientsList"
                  item-text="name"
                  item-value="id"
                  v-model.number="filter.client_id"
                  hide-details
                  no-data-text="Не надено"
                  clearable
                  :search-input.sync="searchClients"
                  @change="customFilter()"
                ></v-autocomplete>
              </v-flex>
              <v-flex xs2 class="px-2">
                <v-autocomplete
                  v-if="shouldDisplayStoreSelect"
                  label="Магазин"
                  :items="storeList"
                  item-text="name"
                  item-value="id"
                  v-model.number="filter.store_id"
                  hide-details
                  no-data-text="Не надено"
                  clearable
                  @change="customFilter()"
                ></v-autocomplete>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-spacer></v-spacer>

          
          <v-btn
            color="info"
            to="/payment-types/"
            >Типы оплат</v-btn
          >

          <v-dialog v-model="dialogForm" persistent max-width="420px">
            <v-btn slot="activator" color="primary" dark class="mb-2"
              >VT</v-btn
            >
            <payment-edit
              v-if="Object.keys(this.editedItem).length > 0"
              :item="editedItem"
              :paymentTypes="paymentTypes"
              :storeList="storeList"
              @cancel="closeDialog()"
            ></payment-edit>
            <!-- <payment-vendor
              v-else
              @cancel="closeDialog()"
            ></payment-vendor> -->
          </v-dialog>
        </v-card-title>

        <v-data-table
          :headers="headersTable"
          :items="paymentsList"
          hide-actions
          no-data-text="Оплат не найдено"
          no-results-text="Оплат не найдено"
          :search="search"
          :pagination.sync="pagination"
        >
          <template slot="items" slot-scope="props">
            <td class="text-xs-right" style="width: 30px;">
              {{ props.item.id }}
            </td>
            <td>{{ new Date(props.item.created_at).toLocaleString() }}</td>
            <td>
              <template v-if="props.item.client">
                {{ props.item.client && props.item.client.name }}
                <br />{{ props.item.client && props.item.client.phone }}
                <br />Баланс:
                {{ props.item.client && props.item.client.bill }}
              </template>
            </td>
            <td>
              <template v-if="props.item.bill_history">
                {{ props.item.bill_history.bill }}
              </template>
            </td>
            <td>{{ props.item.amount }}</td>
            <td>
              {{ (type = paymentTypes.find((item) => item.id === props.item.payment_type_before_refund)) ? type.name : '' }}
              <br />{{ (type = paymentTypes.find((item) => item.id === props.item.payment_type_id)) ? type.name : '' }}
            </td>
            <td>{{ props.item.created_by.name }}</td>
            <td>
              {{ (store = storeList.find((item) => item.id === props.item.store_id)) ? store.name : '' }}
            </td>
            <td>{{ props.item.comment }}</td>
            <td class="text-xs-right" style="width: 110px;">
              <v-icon @click="editItem(props.item)" title="Просмотр">
                visibility
              </v-icon>
            </td>
          </template>
        </v-data-table>
        <v-layout row wrap justify-space-around class="py-2">
          <v-flex xs2 class="px-3">
            <v-text-field
              label="Количество на странице"
              v-model.number="take"
              hide-details
              @change="changeShowElem()"
            ></v-text-field>
          </v-flex>
          <v-flex xs10 class="text-xs-right px-3">
            <v-btn
              small
              color="info"
              class="ml-3"
              :disabled="page === 1"
              @click="prevPage()"
            >
              <v-icon dark>keyboard_arrow_left</v-icon>
            </v-btn>
            <v-btn
              small
              color="info"
              class="ml-3"
              :disabled="paymentsList.length < take"
              @click="nextPage()"
            >
              <v-icon dark>keyboard_arrow_right</v-icon>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card>
    </template>
  </v-container>
</template>

<script>
import axios from 'axios';
import PaymentEdit from './edit.vue';
// import PaymentVendor from './vendor.vue';

export default {
  name: "Payments",
  components: {
    PaymentEdit,
    // PaymentVendor
  },
  data() {
    return {
      loadingData: [
        {
          title: "Получение оплат",
          error: false,
          loading: true,
          color: "cyan",
          id: "payments"
        },
        {
          title: "Получение менеджеров",
          error: false,
          loading: true,
          color: "cyan",
          id: "managers"
        },
        // {
        //   title: "Получение клиентов",
        //   error: false,
        //   loading: true,
        //   color: "cyan",
        //   id: "clients"
        // },
        {
          title: "Получение типов оплат",
          error: false,
          loading: true,
          color: "cyan",
          id: "payment-types"
        },
      ],
      filter: {
        manager_id: null,
        payment_type_id: null,
        start_date: null,
        end_date: null,
        client_id: null,
        store_id: 0,
      },
      searchClients: '',
      timerClients: null,
      dataStartPicker: false,
      dataEndPicker: false,
      search: "",
      headersTable: [
        {
          text: "ID",
          align: "right",
          value: "id",
          filterable: false,
          sortable: true,
        },
        {
          text: "Дата",
          align: "left",
          value: "created_at",
          filterable: false,
          sortable: true,
        },
        {
          text: "Клиент",
          align: "left",
          value: "client.name",
          sortable: false,
        },
        {
          text: "Баланс до",
          align: "left",
          value: "bill_history",
          sortable: false
        },
        {
          text: "Стоимость",
          align: "left",
          value: "amount",
          sortable: true,
        },
        {
          text: "Тип",
          align: "left",
          value: "payment_type_id",
          sortable: true,
        },
        {
          text: "Менеджер",
          align: "left",
          value: "created_by.name",
          sortable: false
        },
        {
          text: "Магазин",
          align: "left",
          value: "store_id",
          sortable: true,
        },
        {
          text: "Комментарий",
          align: "left",
          value: "comment",
          sortable: true,
        },
        {
          text: "",
          align: "right",
          sortable: false,
          value: "action"
        }
      ],
      usersList: [],
      paymentTypes: [],
      clientsList: [],
      storeList:[],
      dialogForm: false,
      editedItem: {},
      pagination: {
        sortBy: "id",
        rowsPerPage: -1,
        descending: true,
      },
      take: 20,
      page: 1,
      tableLoading: false,
      paymentsList: [],
      selectedManagerId: null,
      client: {},
      queryName: "",
      skipClientsQuery: true,
      suggestions: [],
      vendorModal: false,
    };
  },
  watch: {
    searchClients(val) {
      const findClient = this.clientsList.find((item) => item.name === val);
      if (findClient) return false;

      if (val && val.length >= 3) {
        if (this.timerClients) clearTimeout(this.timerClients);

        this.timerClients = setTimeout(() => {
          this.getClients(val);
        }, 500);
      } else {
        this.clientsList = [];
      }
    },
    pagination: {
      handler() {
        this.page = 1;

        this.getPaymentsList();
      },
      deep: true,
    },
  },
  computed: {
    shouldDisplayStoreSelect() {
      // Check conditions for displaying store select
      return (
        (parseInt(localStorage.getItem('store-id')) == 0 && this.adminRole == 'admin')
      );
    },
    loadingDialog: function loadingDialog() {
      const loadData = this.loadingData.filter(
        item => !item.error && !item.loading
      );
      return loadData.length === this.loadingData.length ? 0 : 1;
    },
  },
  methods: {
    getPaymentTypesList() {
      const loadData = this.loadingData.find(item => item.id === 'payment-types');
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payment-types`;

      axios
        .get(url)
        .then((response) => {
          const items = response.data;
          this.paymentTypes = items;

          loadData.title = 'Типы оплат получены!';
          loadData.loading = false;
        })
        .catch((error) => {
          loadData.title = 'Ошибка получения типов оплат!';
          loadData.error = true;
          console.log(error);
        });
    },
    getStoreList() {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/store`;

      axios
        .get(url)
        .then((response) => {
          this.storeList = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getManagerList() {
      const loadData = this.loadingData.find(item => item.id === 'managers');
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/users`;

      axios
        .get(url, {
          params: { group_id: 14}
        })
        .then((response) => {
          this.usersList = response.data;

          loadData.title = 'Менеджеры получены!';
          loadData.loading = false;
        })
        .catch((error) => {
          loadData.title = 'Ошибка получения менеджеров!';
          loadData.error = true;
          console.log(error);
        });
    },
    getClients(searchVal) {
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/clients`;

      axios
        .get(url, {
          params: {
            name_or_phone: searchVal,
            page_limit: 999,
          },
        })
        .then((response) => {
          this.clientsList = response.data.map((item) => {
            return {
              name: `${item.name} (${item.phone})`,
              id: item.id,
            };
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    customFilter() {
      this.page = 1;
      this.getPaymentsList();
    },
    getPaymentsList() {
      const loadData = this.loadingData.find(item => item.id === 'payments');
      const url = `${process.env.VUE_APP_DEV_SERVER_PROXY}/payments`;

      const propsItem = {
        page: this.page,
        page_limit: this.take,
      };

      Object.keys(this.filter).forEach((key) => {
        if (this.filter[key]) {
          propsItem[key] = this.filter[key];
        }
      });

      if (this.pagination.sortBy) {
        const sort = {};
        sort[this.pagination.sortBy] = (this.pagination.descending) ? 'desc_nulls_last' : 'asc_nulls_last';

        propsItem.order_by = JSON.stringify(sort);
      }

      axios
        .get(url, {
          params: propsItem,
        })
        .then((response) => {
          this.paymentsList = response.data;
          loadData.title = 'Оплаты получены!';
          loadData.loading = false;
        })
        .catch((error) => {
          loadData.title = 'Ошибка получения оплат!';
          loadData.error = true;
          console.log(error);
        });
    },
    closeDialog() {
      this.dialogForm = false;
      this.editedItem = {};
    },
    editItem(item) {
      this.editedItem = item;
      this.dialogForm = true;
      console.log("this.editedItem", this.editedItem)
    },
    changeShowElem() {
      localStorage.setItem("countElemPage", this.take);
      this.$store.commit("setCountElemPage", this.take);
      this.page = 1;
      this.getPaymentsList();
    },
    prevPage() {
      this.page -= 1;
      this.getPaymentsList();
    },
    nextPage() {
      this.page += 1;
      this.getPaymentsList();
    },
  },
  mounted() {
    // get store_id and admin role form logged in user
    const store_id = parseInt(localStorage.getItem('store-id'))
    this.filter.store_id = store_id
    const adminRole = JSON.parse(localStorage.getItem('user-group')).code
    this.adminRole = adminRole

    const date = new Date();
    date.setDate(date.getDate() + 1);
    const dateEnd = date.toISOString().split("T")[0];

    date.setDate(date.getDate() - 30);
    const dateStart = date.toISOString().split("T")[0];

    this.filter.start_date = dateStart;
    this.filter.end_date = dateEnd;

    this.take = this.$store.getters.getCountElemPage;

    this.getPaymentsList();
    this.getManagerList();
    this.getPaymentTypesList();
    this.getStoreList();
  }
};
</script>

<style lang="scss" scoped>
.v-table {
  tr:nth-child(even) {
    td {
      background: #f9f9f9;
    }

    &:hover {
      td {
        background: #eee;
      }
    }
  }
}
</style>
